/* The world best clouds */
body,
html {
  height: 100%;
}
.app-container {
  background-image: url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  height: 100vh;
}

.app-container {
  display: flex;
  font-size: 14px;
}

.sidebar {
  width: 225px;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 10px;
  overflow-y: auto;
  border-radius: 5px;
}

.title {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.title:hover {
  background-color: #e9ecef;
}

.main-content {
  width: 100%;
  padding: 10px;
  overflow-y: auto;
}

button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #002583;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}
button:disabled {
  opacity: 0.5;
}
button:disabled:hover {
  background-color: #002583;
}

button:hover {
  background-color: #0091ec;
}

.search-input {
  width: calc(100% - 20px);
  padding: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.controls {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  background-color: #f5f5f5;
}
.controls button {
  width: 100px;
  display: flex;
  align-items: center;
}
.controls span {
  margin-left: 5px;
}
.controls img {
  width: 25px;
}
.json-data {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  white-space: pre-wrap;
  overflow-x: auto;
}

.json-data code {
  font-family: "Courier New", Courier, monospace;
  color: #333;
}

/* Basic Search Result Highlighting */
.json-data .key {
  color: #f44336;
}

.json-data .string {
  color: #8bc34a;
}

.json-data .number {
  color: #002583;
}

.json-data .boolean {
  color: #f17725;
}
.highlight {
  /*who needs hex in 2023*/
  background-color: yellow;
}
.title.has-result {
  border-left: 4px solid #f17725; /* Green bar for items with search results */
  padding-left: 6px;
  position: relative;
  padding-left: 20px;
}
.title.has-result::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #f17725;
}
.title.selected {
  color: #f17725;
  font-weight: bold;
}
.search-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-navigation button {
  width: 100px;
}

.clear-button {
  width: 100%;
}
img {
  margin: 10px 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eee;
  margin: 1em 0;
  padding: 0;
}

/*Login */
.login {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}
.login div {
  display: block;
  margin-bottom: 10px;
}
.login label {
  display: inline-block;
  width: 75px;
}

.modal-wrapper {
  height: max(100%, 100vh);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout {
  margin-top: 20px;
}
